import { graphql } from 'gatsby'
import * as React from 'react'
import {Helmet} from 'react-helmet'
import {Container} from 'reactstrap'
import Layout from '../../components/Layout/Layout'

import './AirlinePage.scss'

export default class AirlinePage extends React.Component<{data: any}> {
    render () {
        let data = this.props.data.contentfulAirlinePage

        return <Layout><Container className='content-page airline-page'>
            <Helmet
                title={data.seoTitle}
                meta={[
                    { name: 'description', content: data.seoDescription.seoDescription },

                    { property: 'og:title', content: data.seoTitle },
                    { property: 'og:description', content: data.seoDescription.seoDescription },
                    {
                        property: 'og:image',
                        content: data.titleImage.gatsbyImageData.images.fallback.src
                    },
                    { property: 'og:image:width', content: '1200'},
                    { property: 'og:image:height', content: '630'},
                ]}
            />

            <h1>{data.title}</h1>

            <div dangerouslySetInnerHTML={{__html: data.body.childMarkdownRemark.html}}/>
        </Container></Layout>
    }
}


export const pageQuery = graphql`
    query AirlinePageQuery($locale: String!, $slug: String!) {
        contentfulAirlinePage(node_locale: {eq: $locale}, slug: {eq: $slug} ) {
            title
            seoTitle
            seoDescription {
                seoDescription
            }
            titleImage {
                gatsbyImageData(layout: CONSTRAINED, width: 1200, height:630)
            }
            body {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`
